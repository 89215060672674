import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';

import { connect } from 'react-redux';
import GridBase from '../common/GridBase';
import ContextMenuWithTrigger from '../common/ContextMenuWithTrigger';
import Modal from '../common/Modal';
import AssetUpdate from './AssetUpdate';
import { AssetGridQuery } from '../../graphql/queries/AssetQueries';
import { DeviceGridQuery } from '../../graphql/queries/DeviceQueries';
import { DeleteAssetMutation } from '../../graphql/mutations/AssetMutations';
import DeleteModel from '../common/DeleteModel';
import AssetNew from './AssetNew';
import ChildAssetGrid from './ChildAssetGrid';
import assetFormFields from './assetFormFields';
import cacheDeleteFromArray from '../../graphql/functions/cacheDeleteFromArray';

function RenderChildAssetGrid(row, handleAddSubAssetClick, handleClick) {
  return (
    <ChildAssetGrid
      handleAddSubAssetClick={handleAddSubAssetClick}
      handleClick={handleClick}
      parent={row}
    />
  );
}

class AssetGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      modalLabel: 'Edit Asset',
      modalType: 'update',
      asset: null,
      parent: null,
      delete: { id: null, name: '' },
      updateFn: null,
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleAddSubAssetClick = this.handleAddSubAssetClick.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleDeleteClose = this.handleDeleteClose.bind(this);
    this.handleMulti = this.handleMulti.bind(this);
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  columns = [
    {
      Header: 'ID',
      accessor: 'id',
      maxWidth: 90,
      filterable: true,
      sortable: true,
    },
    {
      Header: 'Company',
      id: 'companyName',
      accessor: (d) => (d.location && d.location.company ? d.location.company.name : ''),
      minWidth: 70,
      filterable: true,
    },
    {
      Header: 'Company Id',
      id: 'companyId',
      accessor: (d) => (d.location && d.location.company ? d.location.company.id : ''),
      show: false,
    },
    {
      Header: 'Location',
      id: 'locationName',
      accessor: (d) => (d.location ? d.location.name : ''),
      minWidth: 70,
      filterable: true,
    },
    {
      Header: 'Asset',
      accessor: 'name',
      minWidth: 70,
      filterable: true,
    },
    {
      Header: 'Type',
      id: 'assetType',
      accessor: (d) => (d.assetType ? d.assetType.name : ''),
      minWidth: 70,
      filterable: true,
    },
    {
      Header: 'Third Party Id',
      accessor: 'thirdPartyAssetId',
      maxWidth: 90,
      filterable: true,
      sortable: true,
    },
    {
      Header: '',
      maxWidth: 23,
      resizable: false,
      sortable: false,
      Cell: ({ row }) => ContextMenuWithTrigger({
        handleClick: this.handleClick,
        row,
      }),
    },
  ];

  handleAddSubAssetClick(data) {
    console.log('Add subasset: ', data);
    this.setState({
      parent: data,
      modalIsOpen: true,
      modalLabel: 'Add Asset Component',
      modalType: 'new',
    });
  }

  handleClick(e, data, target) {
    if (data.action === 'Edit') {
      console.log('Edit: ', data);
      this.setState({
        asset: data.row,
        modalIsOpen: true,
        modalLabel: 'Edit Asset',
        modalType: 'update',
      });
    } else if (data.action === 'Delete') {
      const refetchQueries = [{ query: DeviceGridQuery }];
      let cacheOptions;
      if (data.row && data.row.parentId && typeof data.row.parentId === 'string') {
        cacheOptions = [{ cachedListName: `childAssets({"parentId":"${data.row.parentId}"})` }];
      } else {
        cacheOptions = [{ cachedListName: 'editableParentAssets' }];
        if (data.row._original.location && data.row._original.location.id) {
          cacheOptions.push({ cachedListName: `assetsByLocation({"locationId":"${data.row._original.location.id}"})` });
        }
      }
      const updateFn = (cache) => { cacheDeleteFromArray({ cache, deletedId: data.row.id, cacheOptions }); };
      this.setState({ refetchQueries, delete: { id: data.row.id, name: data.row.name }, updateFn });
    }
  }

  handleDeleteClose() {
    this.setState({ delete: { id: null, name: '' } });
  }

  handleMulti() {
    console.log('handleMulti', this.props);
    this.props.data.refetch();
  }

  render() {
    const {
      data: { loading, error, assets },
    } = this.props;
    const sorting = [
      { id: 'companyName', asc: true },
      { id: 'locationName', asc: true },
      { id: 'name', asc: true },
    ];
    if (loading) {
      return <p>Loading...</p>;
    } if (error) {
      return <p>Error loading assets!</p>;
    }
    const columns = this.columns.slice(0);
    if (this.props && this.props.auth && this.props.auth.isSuperAdmin) {
      // add owner company can view and monitoring company columns
      columns.splice(columns.length - 1, 0, {
        Header: 'Monitoring Company',
        id: 'monitoringCompanyName',
        accessor: (d) => (d.monitoringCompany ? d.monitoringCompany.name : ''),
        minWidth: 70,
        filterable: true,
      });
      columns.splice(columns.length - 1, 0, {
        Header: 'Monitoring Company Id',
        id: 'monitoringCompanyId',
        accessor: (d) => (d.monitoringCompany ? d.monitoringCompany.id : ''),
        show: false,
      });
      columns.splice(columns.length - 1, 0, {
        Header: 'Owner Can View',
        id: 'parentCompanyCanView',
        accessor: (d) => (d.parentCompanyCanView === false ? 'No' : 'Yes'),
        maxWidth: 50,
        filterable: true,
      });
    }
    const multiSelect = {
      fields: assetFormFields,
      modelName: 'asset',
      onSubmit: this.handleMulti,
    };
    return (
      <div>
        <GridBase
          dataVar={assets}
          columns={columns}
          sorting={sorting}
          multiSelect={multiSelect}
          subComponent={(row) => RenderChildAssetGrid(row, this.handleAddSubAssetClick, this.handleClick)}
        />

        <DeleteModel
          deleteMutation={DeleteAssetMutation}
          refetchQueries={this.state.refetchQueries}
          update={this.state.updateFn}
          id={this.state.delete.id}
          name={this.state.delete.name}
          onClose={this.handleDeleteClose}
        />

        <Modal
          onClose={this.closeModal}
          modalIsOpen={this.state.modalIsOpen}
          contentLabel={this.state.modalLabel}
        >
          {this.state.modalType === 'new' ? (
            <AssetNew
              header={this.state.modalLabel}
              subAsset
              parent={this.state.parent}
            />
          ) : (
            <AssetUpdate asset={this.state.asset} />
          )}
        </Modal>
      </div>
    );
  }
}

function mapStateToProps({ auth }) {
  return { auth };
}
AssetGrid = connect(mapStateToProps)(AssetGrid);
export default graphql(AssetGridQuery, {
  name: 'AssetGridQuery',
  props: ({
    ownProps,
    AssetGridQuery: {
      loading, error, editableParentAssets, refetch,
    },
  }) => ({
    data: {
      assets: editableParentAssets,
      loading,
      error,
      refetch,
    },
  }),
})(AssetGrid);
